import { createApp, nextTick } from 'vue'
import ContextMenu from './v-menu.vue'
import { config } from '@/config' // 确保路径正确
import * as ElIconModules from '@element-plus/icons-vue'

function validateItems(items) {
  if (!Array.isArray(items)) {
    console.error('Invalid items parameter. Expected an array, but received:', items)
    return false
  }
  return true
}

function createContextMenu(el, binding, event) {
  if (!validateItems(binding)) {
    return // 参数错误，取消本次显示
  }

  const menuElement = document.createElement('div')
  document.body.appendChild(menuElement)
  document.body.classList.add('no-scroll')

  const app = createApp(ContextMenu, {
    items: binding,
    position: { x: event.clientX, y: event.clientY },
  })

  // 如果是管理后台, 全局引用
  // if (config.websiteModel === 'admin') {
  //   // 注册所有 Element Plus 图标
  //   for (const iconName in ElIconModules) {
  //     config.elementPlusIconNames.push(iconName);
  //     app.component(iconName, ElIconModules[iconName]);
  //   }
  // }

  const vm = app.mount(menuElement)

  el._contextMenuApp = app
  el._contextMenuElement = menuElement
  el._contextMenuVisible = true
  el._lastEvent = event
  el._bindingValue = binding // 保存绑定值
}

export default {
  mounted(el, binding) {
    // 保存绑定值
    el._bindingValue = binding.value

    el.addEventListener('mousedown', (event) => {
      // 右键按下时的操作
      if (event.button !== 2) {
        return
      }

      // 阻止右键点击事件的传播
      event.stopPropagation()
      event.preventDefault()

      // 如果已有菜单显示，先关闭它
      if (el._contextMenuVisible) {
        el._contextMenuApp.unmount()
        document.body.classList.remove('no-scroll')
        if (document.body.contains(el._contextMenuElement)) {
          document.body.removeChild(el._contextMenuElement)
        }
        el._contextMenuVisible = false
        el._contextMenuElement = null
        el._contextMenuApp = null
      }

      createContextMenu(el, el._bindingValue, event)
    })
  },
  updated(el, binding) {
    if (!validateItems(binding.value)) {
      return // 参数错误，不更新数据
    }

    // 更新保存的绑定值
    el._bindingValue = binding.value
  },
  unmounted(el) {
    // 关闭菜单
    if (el._contextMenuApp && el._contextMenuElement) {
      el._contextMenuApp.unmount()
      document.body.classList.remove('no-scroll')
      if (document.body.contains(el._contextMenuElement)) {
        document.body.removeChild(el._contextMenuElement)
      }
    }
  },
}
